import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import { localize } from '../helpers/helpers'

import SEO from "../components/seo"
import Bio from "../components/about/bio"
import Team from "../components/about/team"
import CTA from '../components/cta'

const AboutPage = ({ data, pageContext }) => {
  const
    info = localize(data.sanityAbout, [pageContext.language]),
    { title, name, gallery, _rawText, individual, team, _rawText2 } = info,
    { t } = useTranslation('common');

  useEffect(() => {
    const wrapper = document.querySelector('#gatsby-focus-wrapper')
    wrapper.style.backgroundColor = "#E3E1DC"
  })
  return (
    <>
      <SEO title={t('about')} lang={pageContext.language} />
      <div className='grid title-container' data-scroll-section>
        <h1
          data-scroll
          className="page-title"
          style={{maxWidth: '530px'}}
          data-sal='fade' data-sal-duration='750'
          data-sal-easing='ease'
        >
          {title.translate}
        </h1>
      </div>
      <Bio name={name} text={_rawText} gallery={gallery}/>
      <Team individual={individual} team={team.gallery} text={_rawText2} />
      <CTA title="contact"/>
    </>
  )
}

export const query = graphql`
  query AboutPage($language: String) {
    sanityAbout {
      title {
        translate(language: $language)
      }
      name
      gallery {
        asset {
          fluid(maxWidth: 980) {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
      _rawText
      individual {
        name
        job {
          translate(language: $language)
        }
        portrait {
          asset {
            fluid(maxWidth: 770) {
              ...GatsbySanityImageFluid_noBase64
            }
          }
        }
      }
      team {
        gallery {
          asset {
            fluid(maxWidth: 980) {
              ...GatsbySanityImageFluid_noBase64
            }
          }
        }
      }
      _rawText2
    }
  }
`

export default AboutPage
