import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import Image from '../image'
import BlockContent from '../block-content'

const Team = ({ individual, team, text }) => {
  const
    { t } = useTranslation('common'),
    [stateTeam, setStateTeam] = useState(0),
    [stateIndividual, setStateIndividual] = useState(0);

  const getClassesTeam = (i) => {
    let
      classes = classNames({
        'slide-wrapper': i !== stateTeam,
        'slide-wrapper slide-current': i === stateTeam
      });
    return classes
  }

  const getClassesIndividual = (i) => {
    let
      classes = classNames({
        'slide-wrapper': i !== stateIndividual,
        'slide-wrapper slide-current': i === stateIndividual
      });
    return classes
  }

  const getSlideTeam = (newState) => {
    switch (newState) {
      case 2:
        return setStateTeam(0)
      default:
        return setStateTeam(newState)
    }
  }

  const getSlideIndividual = (newState) => {
    switch (newState) {
      case individual.length:
        return setStateIndividual(0)
      default:
        return setStateIndividual(newState)
    }
  }

  return (
    <div className="team-container grid" data-scroll-section>
      <div className="team gallery-container" data-scroll data-scroll-speed="1" data-sal='fade' data-sal-duration='750' data-sal-easing='ease' onClick={() => getSlideTeam(stateTeam + 1)}>
        <p className="cta-click up">{t('click')}</p>
        <div className="g-container">
          {team.map((slide, i) => {
            return(
              <div key={i * 100} className={getClassesTeam(i)}>
                <Image fluid={slide.asset.fluid} alt="Gutiérrez F Studio, Event Planning"/>
              </div>
            )
          })}
        </div>
      </div>
      <div className="individual gallery-container" data-scroll data-scroll-speed="2" data-sal='fade' data-sal-duration='750' data-sal-easing='ease' onClick={() => getSlideIndividual(stateIndividual + 1)}>
        <p className="cta-click up">{t('click')}</p>
        <div className="g-container">
          {individual.map((slide, i) => {
            return(
              <div key={i * 100} className={getClassesIndividual(i)}>
                <Image fluid={slide.portrait.asset.fluid} alt="Gutiérrez F Studio, Event Planning"/>
              </div>
            )
          })}
        </div>
        <p className="cta-click">{individual[stateIndividual].name} - {individual[stateIndividual].job.translate}</p>
      </div>
      <div className="block-content" data-scroll data-sal='fade' data-sal-duration='750' data-sal-easing='ease'>
        <BlockContent blocks={text || []}/>
      </div>
    </div>
  )
}

export default Team
