import React from 'react'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'


const CTA = ({ title }) => {
  const { t, i18n } = useTranslation('common');
  return (
    <div className="cta-next-page grid" data-scroll-section>
      <Link to={`/${i18n.language}/${title}`}><h2>{t(`${title}`)}</h2></Link>
    </div>
  )
}

export default CTA
