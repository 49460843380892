import React from 'react'

import Image from '../image'
import BlockContent from '../block-content'

const Bio = ({ name, gallery, text }) => {
  return (
    <div className="bio-container grid" data-scroll-section>
      <div className="gallery-container about" data-scroll data-scroll-speed="1" data-sal='fade' data-sal-duration='750' data-sal-easing='ease'>
        <Image fluid={gallery.asset.fluid} alt="Gutiérrez F Studio, Event Planning"/>
      </div>
      <div className="text-container" data-sal='fade' data-sal-duration='750' data-sal-easing='ease'>
        <h2 data-sal='fade' data-sal-delay="350" data-sal-duration='750' data-sal-easing='ease'>{name}</h2>
        <BlockContent className="block-content" blocks={text || []}/>
      </div>
    </div>
  )
}

export default Bio
